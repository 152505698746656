import React from 'react';
import Layout from 'components/layout/layout';
import './mandatorydisclosure.css'; // Make sure this file exists

const MandatoryDisclosure = () => {
  return (
    <div className="mandatory-disclosure">
      <Layout title="Mandatory Disclosure" />
      <ul className="mandatoryLinks">
        <li>
          <a href="/assets/fystatement/fy/F.Y.2019-20.pdf" target="_blank" rel="noopener noreferrer">
            Financial Statement for the Year 2019-20
          </a>
        </li>
        <li>
          <a href="/assets/fystatement/fy/F.Y.2020-21.pdf" target="_blank" rel="noopener noreferrer">
            Financial Statement for the Year 2020-21
          </a>
        </li>
        <li>
          <a href="/assets/fystatement/fy/F.Y.2021-22.pdf" target="_blank" rel="noopener noreferrer">
            Financial Statement for the Year 2021-22
          </a>
        </li>
        <li>
          <a href="/assets/fystatement/fy/F.Y.2022-23.pdf" target="_blank" rel="noopener noreferrer">
            Financial Statement for the Year 2022-23
          </a>
        </li>
        <li>
          <a href="/assets/fystatement/fy/F.Y.2023-24.pdf" target="_blank" rel="noopener noreferrer">
            Financial Statement for the Year 2023-24
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MandatoryDisclosure;

