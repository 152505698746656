import "./footer.css";

import DataProvider from "hoc/data_provider";
import VisitCounter from "components/visit_counter/visit_counter";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

const FooterSection = () => {
    return (
        <div className="footer-section column">
            <div className="footer-content row">
                <div className="footer-logo column c-c-y c-c-x">
                    <img src="../../assets/logooo.png" alt="footer-logo" />
                    <div className="logo-text">
                        <p className="college_name">GOVERNMENT ENGINEERING COLLEGE</p>
                        <p className="college_name">BHAVNAGAR</p>
                    </div>
                    <div className="social-media row">
                        <div className="social-media-icons row">
                            <div className="social-icon">
                                <a href="https://m.facebook.com/gecbhavnagarofficial/">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="mailto:gec-bhav-dte@gujrat.gov.in">
	    { /* <i className="las la-envelope-open-text"></i>*/}
	    			<FontAwesomeIcon icon={faEnvelopeOpenText} />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="https://instagram.com/gecbhavnagar?utm_medium=copy_link">
	    {/* <i className="lab la-instagram"></i>*/}
	    			<FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </div>
                            <div className="social-icon">
                                <a href="https://twitter.com/gecbhavnagar?t=uJmMkKQOpDLZKGNUdWv8uw&s=08">
	    {/*<i className="lab la-twitter"></i>*/}
	    			<FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <DataProvider
                        endPoint="/web/counter"
                        component={VisitCounter}
                    />
                </div>
                <div className="sections row">
                    <div className="link-section column">
                        <h2 className="link-title">Quick Links</h2>
                        <ul className="footer-navs column">
                            <FooterNavItem title="Team" path="/team" />
	    
                            <FooterNavItem title="Campus Map" path="/campusmap"/>
                            <FooterNavItem title="Important Links" path="/importantlinks"/>
                            <FooterNavItem title="Admission Process" path="/admissionprocess"/>
                        </ul>
                    </div>
                    <div className="link-section column">
                        <h2 className="link-title">Important Info.</h2>
                        <ul className="footer-navs column">
                            <FooterNavItem title="Right to Information" path="/about/rti"/>
                            <FooterNavItem title="Mandatory Disclosure" path="/mandatorydisclosure"/>
                        </ul>
                    </div>
                    <div className="link-section column">
                        <h2 className="link-title">Contact Us</h2>
                        <ul className="footer-navs column">
                            <FooterNavItem title="Government Engineering College, Bhavnagar, Nr. Sir BPTI Campus, Vidhyanagar, Bhavnagar, Gujarat 364002, India." />
                            <FooterNavItem title="+91 278 252 5354" />
                        </ul>
                    </div>
                </div>
            </div>
            <div className="terms-condition row r-c-y">
                <p>Copyright  © {(new Date().getFullYear())} GecBhavnagar. All rights reserved.</p>
                <p>Privacy Policy | Terms & Condition</p>
            </div>
        </div>
    );
};

export default FooterSection;

const FooterNavItem = ({ title,path="/#" }) => {
    return (
        <li className="footer-navItem">
            <NavLink to={path}>{title}</NavLink>
        </li>
    );
};
