import RenderRoutes from "./render_routes";
import {
    MainContent,
    WysiwygPage,
    DepartmentPage,
    EventNewsPage,
    DepartmentEventNewsPage,
    AchievementsPage,
    AchievementDetails,
    VisitorsPage,
    VisitorDetails,
    AboutTNP,
    AllFacultiesPage,
    Canteen,
    ContactUsPage,
    FacultyProfile,
    GalleryPage,
    Hostels,
    Library,
    CoE,
    PlacedStudents,
    PlacementNews,
    Recruiters,
    StaffPage,
    Summary,
    Team,
    CampusMap,
    MandatoryDisclosure,
    ImportantLinks,
    AdmissionProcess,
    Clubs,
    UnderConstruction,
    AluminiAssociation,
    AluminiTrust,
    AluminiCoordinators,
} from "pages/index";
const ROUTES = [
    {
        path: "/",
        key: "homePage",
        exact: true,
        component: () => <MainContent />,
    },
    {
        path: "/about/:id",
        key: "wysiwyg",
        component: () => <WysiwygPage />,
    },
    {
        path: "/departments/:department",
        key: "DepartmentPage",
        component: () => <DepartmentPage />,
    },
    {
        path: "/show/:type",
        key: "eventNewsPage",
        component: () => <EventNewsPage />,
    },
    {
        path: "/open/:type/:department",
        key: "departmentEventNewsPage",
        component: () => <DepartmentEventNewsPage />,
    },

    {
        path: "/achievements/",
        key: "achievements",
        component: RenderRoutes,
        routes: [
            {
                path: "/achievements/",
                exact: true,
                key: "achievementsPage",
                component: () => <AchievementsPage />,
            },
            {
                path: "/achievements/:id",
                key: "achievementsDetails",
                component: () => <AchievementDetails />,
            },
        ],
    },
    {
        path: "/visitors/",
        key: "visitors",
        component: RenderRoutes,
        routes: [
            {
                path: "/visitors/",
                exact: true,
                key: "visitorsPage",
                component: () => <VisitorsPage />,
            },
            {
                path: "/visitors/:id",
                key: "visitorDetails",
                component: () => <VisitorDetails />,
            },
        ],
    },
    {
        path: "/placement",
        key: "placement",
        component: RenderRoutes,
        routes: [
            {
                path: "/placement/about",
                key: "placementAbout",
                component: () => <AboutTNP />,
            },
            {
                path: "/placement/news",
                key: "placementNews",
                component: () => <PlacementNews />,
            },
            {
                path: "/placement/summary",
                key: "placementSummary",
                component: () => <Summary />,
            },
            {
                path: "/placement/placed-students",
                key: "placementPlacedStudents",
                component: () => <PlacedStudents />,
            },
            {
                path: "/placement/recruiters",
                key: "placementRecruiters",
                component: () => <Recruiters />,
            },
        ],
    },
    {
        path: "/alumini",
        key: "alumini",
        component: RenderRoutes,
        routes: [
            {
                path: "/alumini/association",
                key: "aluminiAssociation",
                component: () => <AluminiAssociation />,
            },
            {
                path: "/alumini/alumini-trust-details",
                key: "aluminiTrust",
                component: () => <AluminiTrust />,
            },
            {
                path: "/alumini/alumini-coordinator-details",
                key: "aluminiCoordinators",
                component: () => <AluminiCoordinators />,
            }
        ],
    },
    {
        path: "/infrastructure",
        key: "infrastructure",
        component: RenderRoutes,
        routes: [
            {
                path: "/infrastructure/hostel",
                key: "infrastructureHostel",
                component: () => <Hostels />,
            },
            {
                path: "/infrastructure/canteen",
                key: "infrastructureCanteen",
                component: () => <Canteen />,
            },
            {
                path: "/infrastructure/library",
                key: "infrastructureLibrary",
                component: () => <Library />,
            },
  	    {
                path: "/infrastructure/coe",
                key: "infrastructureCoE",
                component: () => <CoE />,
            },

        ],
    },

    {
        path: "/faculty",
        key: "faculty",
        component: RenderRoutes,
        routes: [
            {
                path: "/faculty",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/c.e",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/i.t",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/ci.e",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/e&c.e",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/m.e",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/p.e",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/a.m",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/co.e",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/genral",
                key: "facultyPage",
                exact: true,
                component: () => <AllFacultiesPage />,
            },
            {
                path: "/faculty/:id",
                key: "facultyProfile",

                component: () => <FacultyProfile />,
            }
        ],
    },
    {
        path: "/others/:id",
        key: "wysiwyg",
        component: () => <WysiwygPage />,
    },
    {
        path: "/staff",
        key: "staff",
        component: () => <StaffPage />,
    },
    {
        path: "/contact-us",
        key: "contactUspage",
        component: () => <ContactUsPage />,
    },
    {
        path: "/gallery",
        key: "gallery",
        component: () => <GalleryPage />,
    },
    {
        path: "/team",
        key: "team",
        component: () => <Team />,
    },
    {
        path: "/campusmap",
        key: "campusmap",
        component: () => <CampusMap />,
    },
    {
        path: "/mandatorydisclosure",
        key: "mandatorydisclosure",
        component: () => <MandatoryDisclosure />,
    },
    {
        path: "/importantlinks",
        key: "importantlinks",
        component: () => <ImportantLinks />,
    },
    {
        path: "/admissionprocess",
        key: "admissionprocess",
        component: () => <AdmissionProcess />,
    },
    {
        path: "/clubs",
        key: "clubs",
        component: () => <Clubs />,
    },
    {
        path: "/under-construction",
        key: "underConstruction",
        component: () => <UnderConstruction/>,
    },
];

export default ROUTES;
